<script setup lang="ts">

import { useVuelidate } from "@vuelidate/core";
import { customValidators } from "@/i18n/utils/i18n-validators";

const { pushSuccess, pushError } = useNotificationsEx();
const { required, sameAs, minLength, email } = customValidators();
const { isLoggedIn, user } = useUser();

const runtimeConfig = useRuntimeConfig();

const friendlyCaptcha = ref(null);

const message = reactive({
  salutation: "",
  firstname: "",
  lastname: "",
  phone: "",
  email: "",
  customerNumber: "",
  date: "",
  time: "",
  question: "",
  privacy: false,
  captcha: false,
});

onMounted(async () => {
  if (isLoggedIn.value) {
    message.email = user.value?.email ?? '';
    message.salutation = user.value?.defaultBillingAddress?.salutation?.salutationKey ?? '';
    message.firstname = user.value?.firstName ?? '';
    message.lastname = user.value?.lastName ?? '';
    message.customerNumber = user.value?.customerNumber?.toString() ?? '';
    message.phone = user.value?.defaultBillingAddress?.phoneNumber ?? '';
  }
});

const salutationOptions = ref([
  {
    key: "",
    label: "Keine Angabe",
  },
  {
    key: "mx",
    label: "Divers",
  },
  {
    key: "mrs",
    label: "Frau",
  },
  {
    key: "mr",
    label: "Herr",
  },
]);

const timeOptions = ref([
  {
    key: "morning",
    label: "Vormittags (8–11 Uhr)",
  },
  {
    key: "noon",
    label: "Mittags (11–14 Uhr)",
  },
  {
    key: "afternoon",
    label: "Nachmittags (14–17 Uhr)",
  },
]);

const questionOptions = ref([
  {
    key: "Artikel",
    label: "Artikel",
  },
  {
    key: "BestellungLieferung",
    label: "Bestellung/Lieferung",
  },
  {
    key: "Rechnung",
    label: "Rechnung",
  },
  {
    key: "Reklamation",
    label: "Reklamation",
  },
  {
    key: "Retoure",
    label: "Retoure",
  },
]);

const rules = computed(() => ({
  salutation: {
    oneOf: (value: string) =>
      salutationOptions.value
        .map((val) => val.key)
        .concat(["", undefined])
        .includes(value),
  },
  firstname: {
    required,
    minLength: minLength(3),
  },
  lastname: {
    required,
    minLength: minLength(3),
  },
  phone: {
    required,
  },
  email: {
    email,
  },
  customerNumber: {},
  date: {
    required,
  },
  time: {
    required,
    oneOf: (value: string) => timeOptions.value.map((val) => val.key).includes(value),
  },
  question: {
    required,
    oneOf: (value: string) => questionOptions.value.map((val) => val.key).includes(value),
  },
  privacy: {
    required: sameAs(true),
  },
}));

const $v = useVuelidate(rules, message);
const { triggerFriendlyCaptcha, focusOnFirstInvalidField } = useFormHelpers($v);

const loading = ref(false);

const onSubmit = async () => {
  loading.value = true;

  $v.value.$touch();
  const valid = await $v.value.$validate();

  if (valid && (await triggerFriendlyCaptcha(message))) {

    try {
      const response = await $fetch(`/api/callbackservice`, {
        method: "post",
        body: message,
        headers: {
          "Content-Type": "application/json; charset: UTF-8",
          Accept: "application/json; charset: UTF-8",
        },
      });

      if (response && response.state === "error") {
        pushError(response.statusMessage);
      }
      else 
      {
        message.date = "";
        message.time = "";
        message.question = "";
        message.customerNumber = "";
        message.salutation = "";
        message.firstname = "";
        message.lastname = "";
        message.phone = "";
        message.email = "";
        message.privacy = false;
        pushSuccess("Vielen Dank für Ihre Nachricht!");
      }
    } catch (err: any) {
      pushError("Die Anfrage konnte nicht bearbeitet werden.");
    }

    $v.value.$reset();
      
    message.captcha = false;

    friendlyCaptcha.value.recreate();

  } else {
    pushError("Überprüfen Sie Ihre Eingaben, sie sind entweder fehlerhaft oder fehlen..");
    focusOnFirstInvalidField();
  }

  loading.value = false;
};

const friendlyCaptchaChange = (state) => {
  message.captcha = state !== false ? state : false;
};
</script>

<template>
  <form @submit.prevent="onSubmit" class="flex flex-col gap-8 lg:grid lg:grid-cols-2 lg:gap-24">
    <fieldset>
      <legend class="text-h3 text-maas-typography-headline-primary">Ihre Kontaktdaten</legend>

      <div class="sm:grid sm:grid-cols-2 sm:gap-4">
        <SharedFormLabel label="Anrede">
          <SharedFormSelect
            id="salutation"
            v-model="message.salutation"
            :elems="salutationOptions"
            :valid="!$v.salutation.$invalid || !$v.salutation.$dirty"
            :error="$v.salutation?.$silentErrors?.[0]?.$message ? String($v.salutation.$silentErrors[0].$message) : ''"
            @change="$v.salutation.$touch()"
          />
        </SharedFormLabel>
      </div>

      <div class="sm:grid sm:grid-cols-2 sm:gap-4">
        <SharedFormLabel label="Vorname*">
          <SharedFormInput
            id="firstname"
            v-model="message.firstname"
            :valid="!$v.firstname.$invalid || !$v.firstname.$dirty"
            :error="$v.firstname?.$silentErrors?.[0]?.$message ? String($v.firstname.$silentErrors[0].$message) : ''"
            @change="$v.firstname.$touch()"
          />
        </SharedFormLabel>

        <SharedFormLabel label="Nachname*">
          <SharedFormInput
            id="lastname"
            v-model="message.lastname"
            :valid="!$v.lastname.$invalid || !$v.lastname.$dirty"
            :error="$v.lastname?.$silentErrors?.[0]?.$message ? String($v.lastname.$silentErrors[0].$message) : ''"
            @change="$v.lastname.$touch()"
          />
        </SharedFormLabel>
      </div>

      <div class="sm:grid sm:grid-cols-2 sm:gap-4">
        <SharedFormLabel label="Telefon*">
          <SharedFormInput
            id="telefon"
            v-model="message.phone"
            :valid="!$v.phone.$invalid || !$v.phone.$dirty"
            :error="$v.phone?.$silentErrors?.[0]?.$message ? String($v.phone.$silentErrors[0].$message) : ''"
            @change="$v.phone.$touch()"
          />
        </SharedFormLabel>

        <SharedFormLabel label="E-Mail">
          <SharedFormInput
            id="email"
            v-model="message.email"
            :valid="!$v.email.$invalid || !$v.email.$dirty"
            :error="$v.email?.$silentErrors?.[0]?.$message ? String($v.email.$silentErrors[0].$message) : ''"
            @change="$v.email.$touch()"
          />
        </SharedFormLabel>
      </div>

      <SharedFormLabel label="Meine Kundennummer">
        <SharedFormInput
          id="customerNumber"
          v-model="message.customerNumber"
          :valid="!$v.customerNumber.$invalid || !$v.customerNumber.$dirty"
          :error="$v.customerNumber?.$silentErrors?.[0]?.$message ? String($v.customerNumber.$silentErrors[0].$message) : ''"
          @change="$v.customerNumber.$touch()"
        />
      </SharedFormLabel>
    </fieldset>

    <fieldset class="@container">
      <legend class="text-h3 text-maas-typography-headline-primary">Was können wir für Sie tun?</legend>

      <div class="sm:grid sm:grid-cols-2 sm:gap-4">
        <SharedFormLabel label="Datum*">
          <SharedFormInput
            id="date"
            type="date"
            v-model="message.date"
            :valid="!$v.date.$invalid || !$v.date.$dirty"
            :error="$v.date?.$silentErrors?.[0]?.$message ? String($v.date.$silentErrors[0].$message) : ''"
            @change="$v.date.$touch()"
          />
        </SharedFormLabel>

        <SharedFormLabel label="Bevorzugte Zeit:*">
          <SharedFormSelect
            id="time"
            v-model="message.time"
            :elems="timeOptions"
            :valid="!$v.time.$invalid || !$v.time.$dirty"
            :error="$v.time?.$silentErrors?.[0]?.$message ? String($v.time.$silentErrors[0].$message) : ''"
            placeholder="Bitte wählen..."
            @change="$v.date.$touch()"
          />
        </SharedFormLabel>
      </div>

      <SharedFormLabel label="Ich habe eine Frage zu:*">
        <SharedFormSelect
          id="question"
          v-model="message.question"
          :elems="questionOptions"
          :valid="!$v.question.$invalid || !$v.question.$dirty"
          :error="$v.question?.$silentErrors?.[0]?.$message ? String($v.question.$silentErrors[0].$message) : ''"
          placeholder="Bitte wählen..."
          @change="$v.time.$touch()"
        />
      </SharedFormLabel>

      <SharedFormLabelCheck 
        class="my-8 w-full"
        label="Ich akzeptiere die Datenschutzbestimmungen *" 
        :error="$v.privacy.$invalid && $v.privacy.$dirty && 
                $v.privacy?.$silentErrors?.[0]?.$message ? String($v.privacy.$silentErrors[0].$message) : ''">
        <SharedFormCheckbox
          option="privacy"
          :valid="!$v.privacy.$invalid || !$v.privacy.$dirty"
          :selected="message.privacy"
          @select="message.privacy = !message.privacy"
        />
      </SharedFormLabelCheck>

      <div class="flex gap-8 flex-col @xl:flex-row @xl:justify-between @xl:items-start">
        <VueFriendlyCaptcha
          ref="friendlyCaptcha"
          :sitekey="runtimeConfig.public.FRIENDLY_CAPTCHA_SITEKEY"
          puzzle-endpoint__="https://eu-api.friendlycaptcha.eu/api/v1/puzzle"
          language="de"
          @done="friendlyCaptchaChange"
          @error="friendlyCaptchaChange(false)"
        />

        <div class="@xl:text-right">
          <SharedFormButton layout="primary" size="big" type="submit" class="w-full" :loading="loading" :disabled="loading">
            Jetzt absenden
          </SharedFormButton>
          <p class="text-maas-typography-text-grey-medium mt-4 text-small">
            Die mit einem Stern (*) markierten Felder sind Pflichtfelder.
          </p>
        </div>
      </div>

    </fieldset>
  </form>
</template>
